const initializeBanner = () => {
  const BANNER_KEY = "banner_closed";

  const banner = document.querySelector(".js-top-banner");
  if (!banner) return;

  const closeButton = banner.querySelector(".js-top-banner-close");
  if (!closeButton) return;

  // バナーの表示制御
  const shouldShowBanner = () => {
    const closedDate = sessionStorage.getItem(BANNER_KEY);
    if (!closedDate) return true;

    const interval = 3 * 24 * 60 * 60 * 1000; // 3日
    const diff = new Date().getTime() - new Date(closedDate).getTime();

    return diff > interval;
  };

  // 初期表示の制御
  if (shouldShowBanner()) {
    banner.classList.remove("is-hidden");
  } else {
    banner.classList.add("is-hidden");
  }

  // 閉じるボタンのイベントハンドラ
  closeButton.addEventListener("click", () => {
    banner.classList.add("is-hidden");
    sessionStorage.setItem(BANNER_KEY, new Date().toISOString());
  });
};

export default initializeBanner;
